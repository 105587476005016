import { RouteRecordRaw } from 'vue-router';
import { authGuard } from '@luminsmart/building-blocks';
export const organizationRoutes: RouteRecordRaw[] = [
  {
    name: 'organizationLayout',
    path: '/organizations',
    component: () => import('layouts/OrganizationLayout.vue'),
    children: [
      {
        name: 'Organizations',
        path: '/organizations',
        component: () =>
          import('src/pages/organization/SwitchOrganization.vue'),
        beforeEnter: authGuard,
      },
      {
        name: 'OrganizationHome',
        path: '/organizations/:orgId',
        component: () => import('src/pages/organization/Home.vue'),
        beforeEnter: authGuard,
      },
      {
        name: 'OrganizationUsers',
        path: '/organizations/:orgId/users',
        component: () => import('src/pages/organization/ManageUsers.vue'),
        beforeEnter: authGuard,
      },
    ],
  },
];
